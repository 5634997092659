import React from "react"
import Button from "../components/Button"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div className="py-8 text-center">
      <h1 className="font-Orbitron mb-4 text-3xl">404: Page Not Found</h1>

      <Link to="/">Return to Site</Link>
    </div>
  </Layout>
)

export default NotFoundPage
